import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NiceAvatar from 'react-nice-avatar';
import axios from 'axios';
import {
  Box,
  Flex,
  Heading,
  Text,
  Avatar,
  useMediaQuery,
  VStack,
  Icon,
  useColorMode,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { FiMessageSquare, FiBarChart, FiInstagram, FiMenu, FiGrid, FiCalendar } from 'react-icons/fi';
import ProfilePictureSelector from './ProfilePictureSelector';
import Integrations from './Integrations';
import Today from './Today';
import ChatComponent from './ChatComponent';
import Performance from './Performance';
import PerformanceGauges from './Performance/PerformanceGauges';
import Social from './Social';
import SignOut from './SignOut';
import ProfileSettings from './ProfileSettings';
import AccountSettings from './AccountSettings';
import Preferences from './Preferences';

const UserProfile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isSquareConnected, setIsSquareConnected] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 600px)');
  const { colorMode } = useColorMode();
  const toast = useToast();
  const bgColorMode = colorMode === 'light' ? 'white' : 'gray.800';
  const complementaryColors = {
    primary: "blue.500",
    secondary: "teal.400",
    tertiary: "cyan.400",
    quaternary: "purple.400",
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('chat');
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [isProfileSettingsOpen, setIsProfileSettingsOpen] = useState(false);
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(false);
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await axios.get('https://sbscoaches.com/api/user_info', {
        withCredentials: true,
      });
      if (response.data) {
        setUserInfo(response.data);
        setIsSquareConnected(!!response.data.square_access_token);
      } else {
        throw new Error('Failed to fetch user info');
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      toast({
        title: 'Error fetching user info',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  const handleAvatarChange = async (formData) => {
    try {
      const response = await fetch('https://sbscoaches.com/api/update_avatar', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (response.ok) {
        const updatedUserInfo = await response.json();
        setUserInfo(prevInfo => ({
          ...prevInfo,
          avatar: updatedUserInfo.avatar
        }));
        setIsAvatarModalOpen(false);
        toast({
          title: 'Avatar updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to update avatar');
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
      toast({
        title: 'Error updating avatar',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // onSave callback to update local state after profile save
  const handleProfileSave = (updatedProfile) => {
    setUserInfo(updatedProfile); // Update userInfo with the updated profile data
    setIsProfileSettingsOpen(false); // Close the modal after saving
  };

  // onSave callback to update local state after preferences save
  const handlePreferencesSave = (updatedPreferences) => {
    setUserInfo(prevUserInfo => ({
      ...prevUserInfo,
      ...updatedPreferences, // Merge updated preferences with existing user info
    }));
    setIsPreferencesOpen(false); // Close the modal after saving
  };

  const completeSquareConnection = useCallback(async (code, state) => {
    try {
      const response = await axios.post('https://sbscoaches.com/api/complete-square-oauth',
        { code, state },
        { withCredentials: true }
      );
      if (response.data.success) {
        toast({
          title: 'Success',
          description: 'Connected to Square successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setActiveTab('integrations');
        await fetchUserInfo(); // Re-fetch user info to update Square connection status
      } else {
        throw new Error(response.data.error || 'Failed to complete Square connection');
      }
    } catch (error) {
      console.error('Error completing Square connection:', error);
      toast({
        title: 'Error',
        description: 'Failed to connect to Square. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast, setActiveTab, fetchUserInfo]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    if (code && state) {
      completeSquareConnection(code, state);
      // Clear the URL parameters after processing
      navigate(location.pathname, { replace: true });
    }
  }, [location, completeSquareConnection, navigate]);

  const handleSettingsClick = () => {
    // Implement settings logic here
    console.log("Settings clicked");
  };

  const handleSignOut = () => {
    // Implement your sign out logic here
    console.log('User signed out');
    // You might want to clear user data, tokens, etc.
    // Then redirect to the login page or home page
    navigate('/login');
  };

  const renderAvatar = () => {
    if (!userInfo || !userInfo.avatar) {
      return (
        <Avatar
          size={isMobile ? 'md' : 'lg'}
          name={userInfo?.name}
          onClick={() => setIsAvatarModalOpen(true)}
          cursor="pointer"
        />
      );
    }

    try {
      const avatarData = JSON.parse(userInfo.avatar);
      return (
        <Box
          width={isMobile ? '48px' : '64px'}
          height={isMobile ? '48px' : '64px'}
          onClick={() => setIsAvatarModalOpen(true)}
          cursor="pointer"
        >
          <NiceAvatar style={{ width: '100%', height: '100%' }} {...avatarData} />
        </Box>
      );
    } catch {
      return (
        <Avatar
          size={isMobile ? 'md' : 'lg'}
          name={userInfo.name}
          src={userInfo.avatar}
          onClick={() => setIsAvatarModalOpen(true)}
          cursor="pointer"
        />
      );
    }
  };

  const renderContent = () => {
    // Added a dashboard view that includes performance gauges
    const renderDashboard = () => (
      <Box>
        {isSquareConnected && (
          <Box mb={6}>
            <PerformanceGauges />
          </Box>
        )}
        <ChatComponent businessCategory={userInfo.businessCategory} />
      </Box>
    );

    switch (activeTab) {
      case 'today':
        return <Today userInfo={userInfo} />;
      case 'chat':
        return renderDashboard();   //  Show gauges with chat by default //<ChatComponent businessCategory={userInfo.businessCategory} />;
      case 'performance':
        return <Performance onShowIntegrations={() => setActiveTab('integrations')} />;
       // return <Performance />;
      case 'social':
        return <Social userInfo={userInfo} />;
      case 'integrations':
        return <Integrations isSquareConnected={isSquareConnected} onConnectionChange={fetchUserInfo} />;
      default:
        return renderDashboard(); // Showing gauges by default
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!userInfo) {
    return <Box>No user information available.</Box>;
  }

  const handleCommunityClick = () => {
    navigate('/community');
  };

  return (
    <Box minHeight="100vh" bg={bgColorMode} color={colorMode === 'light' ? 'black' : 'white'}>
      <VStack spacing={4} p={4} align="stretch" height="100vh">
        {/* Header */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          bg={complementaryColors.primary}
          p={4}
          borderRadius="md"
        >
          <Flex alignItems="center">
            <Box onClick={() => setIsAvatarModalOpen(true)} cursor="pointer">
              {renderAvatar()}
            </Box>
            <Box ml={4}>
              <Heading as="h1" size={isMobile ? 'md' : 'lg'} color="white">
                Welcome, {userInfo.name}! 🚀
              </Heading>
              <Text fontSize={isMobile ? 'sm' : 'md'} color="white">{userInfo.email}</Text>
            </Box>
          </Flex>
          <Flex>
            <Menu>
            </Menu>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<FiMenu />}
                variant="ghost"
                color="white"
                aria-label="Settings"
                ml={2}
                onClick={handleSettingsClick}
              />
              <MenuList>
                <MenuItem onClick={() => setIsProfileSettingsOpen(true)}>Profile Settings</MenuItem>
                <MenuItem onClick={() => setIsAccountSettingsOpen(true)}>Account Settings</MenuItem>
                <MenuItem onClick={() => setIsPreferencesOpen(true)}>AI Preferences</MenuItem>
                <MenuItem onClick={handleCommunityClick}>Tools & Resources</MenuItem>
                <MenuItem>
                  <SignOut onSignOut={handleSignOut} />
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {/* Main content area */}
        <Box flex={1} overflowY="auto">
          {renderContent()}
        </Box>

        {/* Bottom navigation */}
        <Flex justifyContent="space-around" width="100%" p={2} bg="white" borderTop="1px" borderColor="gray.200">
          {[
            { icon: FiCalendar, label: 'Today', tab: 'today' },
            { icon: FiMessageSquare, label: 'Chat', tab: 'chat' },
            { icon: FiBarChart, label: 'Performance', tab: 'performance' },
            { icon: FiInstagram, label: 'Social', tab: 'social' },
            { icon: FiGrid, label: 'Integrations', tab: 'integrations' }
          ].map(({ icon, label, tab }) => (
            <VStack key={tab} spacing={1} align="center">
              <Icon
                as={icon}
                boxSize={6}
                color={activeTab === tab ? complementaryColors.primary : 'gray.600'}
                onClick={() => setActiveTab(tab)}
                cursor="pointer"
              />
              <Text fontSize="xs">{label}</Text>
            </VStack>
          ))}
        </Flex>
      </VStack>

      {/* Avatar Modal */}
      <Modal isOpen={isAvatarModalOpen} onClose={() => setIsAvatarModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Profile Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProfilePictureSelector
              currentAvatar={userInfo.avatar}
              onAvatarChange={handleAvatarChange}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Profile Settings Modal */}
      <Modal isOpen={isProfileSettingsOpen} onClose={() => setIsProfileSettingsOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Profile Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Pass userInfo and handleProfileSave as onSave */}
            <ProfileSettings userProfile={userInfo} onSave={handleProfileSave} />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Account Settings Modal */}
      <Modal isOpen={isAccountSettingsOpen} onClose={() => setIsAccountSettingsOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Account Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AccountSettings userInfo={userInfo} />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Profile Settings Modal */}
      <Modal isOpen={isPreferencesOpen} onClose={() => setIsPreferencesOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preferences</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Pass userInfo and handlePreferencesSave as onSave */}
            <Preferences userInfo={userInfo} onSave={handlePreferencesSave} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserProfile;
