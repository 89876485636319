import React from 'react';
import { Box, Heading, VStack, List, ListItem, Link, Text, Icon, Button, Flex, useMediaQuery } from '@chakra-ui/react';
import { FaUserFriends } from 'react-icons/fa';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import ScheduleCoaching from './ScheduleCoaching';

const forms = [
  {
    category: "Business Tools",
    items: [
      { id: 1, title: "SBS Inventory", link: "https://docs.google.com/spreadsheets/d/1nwH7GNp9IE3iZpCuwHG0tXwfnW2reeEkbCLqxZP7HQ8/edit?usp=sharing" },
      { id: 2, title: "Debt Reduction Calculator", link: "https://docs.google.com/spreadsheets/d/1KBwLq6SElczt9OqFJqAZOYd96lOtb8S2TuR-galrp8w/edit?usp=sharing" },
      { id: 3, title: "SBS WEEKLY Profit & Loss", link: "https://docs.google.com/spreadsheets/d/1L4phSXaRBpetvIRi5q8BNgD4ITBi0uhuYNBJqDdB0PY/edit?usp=sharing" },
      { id: 4, title: "SBS Simple KPI's", link: "https://docs.google.com/spreadsheets/d/1QPcNiNC2adMmzLQSayi13bRRlJSBWYHusLN6UbWmHWE/edit?usp=sharing" },
      { id: 5, title: "SBS Performance Coaching", link: "https://docs.google.com/forms/d/1RPUwIVYhrncqWqCEuh46LOwDZxpLT0HiYUk6qN0QUrc/edit" },
      { id: 6, title: "Client Profile", link: "https://docs.google.com/forms/d/1WA8tLx1o4eDQ2sYGCfp9JuyD98a2KhjBwpD6P6Stg5c/edit" },
      { id: 7, title: "Cancellation Form", link: "https://docs.google.com/forms/d/1uIFBt3CuCb63yKbqjlnmrjtsYZWUHHMWJBv0UsdiDbU/edit" },
      { id: 8, title: "SBS Monthly KPI", link: "https://docs.google.com/spreadsheets/d/1NR2CgxNX6hSRuOnHl2Jk0KSA1PAO_CWp-LJHZWc6oMk/edit?usp=sharing" },
      { id: 9, title: "SBS Promotion Calculator", link: "https://docs.google.com/spreadsheets/d/1lETVp4_xM-MECYX7n76WjlwDZLibgVnXjZ1aoPd91cU/edit?gid=91531008#gid=91531008" },
      { id: 10, title: "Service Sales Target to Rationalize Rent", link: "https://docs.google.com/spreadsheets/d/1z96q_Kb8qecs8vA35-McLkiMRjVjnH9ksyS3D_WN-P8/edit?gid=0#gid=0" },
      { id: 11, title: "Monthly Profit and Loss", link: "https://docs.google.com/spreadsheets/d/1L4phSXaRBpetvIRi5q8BNgD4ITBi0uhuYNBJqDdB0PY/edit?gid=1506610771#gid=1506610771" },
      { id: 12, title: "Yearly Profit and Loss", link: "https://docs.google.com/spreadsheets/d/1SHKrTiSd8uMsQviYF_9vcfp3BHdj2nlHqIpiW4VgdgU/edit?gid=1457660069#gid=1457660069" },
    ]
  },
  {
    category: "Goal Setting",
    items: [
      { id: 13, title: "SMART Goals", link: "https://docs.google.com/document/d/1J_16RegZawo-7HdV23dwWWWyvb9yrb_7IMLXAi4Wlco/edit?usp=sharing" },
      { id: 14, title: "SBS Goal Setting Strategy", link: "https://docs.google.com/spreadsheets/d/1dlv40k4l6wjANfiQ0FG8wuOSB_KlhqiyipPElR7_H_E/edit?gid=0#gid=0" },
      { id: 15, title: "Example of SBS Goal Setting Strategy", link: "https://docs.google.com/spreadsheets/d/1-fg7pnD9iX9TJqiujrffmOlwoLygY8Jow1h4yH-IV-g/edit?usp=sharing" },
    ]
  },
  {
    category: "Business Relationships",
    items: [
      { id: 16, title: "Payment Denied Letter and Text", link: "https://docs.google.com/document/d/1iD5gxKoE7vI7l2hkZwiE5BOISEvZt7yVLgUYah0a-_Y/edit?usp=sharing" },
      { id: 17, title: "Price Adjustment", link: "https://docs.google.com/document/d/1ZHn2cjssz1HFaI5xP-K5I3lymDS-feAGAWuCzGrSx8A/edit?usp=sharing" },
      { id: 18, title: "Punctuality Policy", link: "https://docs.google.com/document/d/1bsu5D8idxbXDb02mD0wyLLqhapVSXlr0wfwP60Dz8Yo/edit?usp=sharing" },
      { id: 19, title: "Standard of Conduct & Policy", link: "https://docs.google.com/document/d/1Qs8ejkqeOhYqGwDw0GTH-XZ6UoF1p2jFdm9bwo6XUFU/edit?usp=sharing" },
      { id: 20, title: "Service Dissatisfaction", link: "https://docs.google.com/document/d/1w-Qz90yoCbNvLStq_TnS08JEejIJT5Y_v0ofxaV85Xo/edit?usp=sharing" },
      { id: 21, title: "Rescheduling, Cancellations", link: "https://docs.google.com/document/d/13SANPadc5wnT6PH3mjasoSDWmGAuOnxajE1KtM-PAkA/edit?usp=sharing" },
      { id: 22, title: "Client Gratitude Letter", link: "https://docs.google.com/document/d/14rfkPMK7bRHmUQ4yCCzRDJC9rjf9zDd4K5wUjgWgaD4/edit?usp=sharing" },
      { id: 23, title: "Guest Consultation for Nails", link: "https://docs.google.com/forms/d/1OhQE61CZkJ0ByU_KJb3KFod7nFKkcfM-OWBBqiSLxVQ/edit" },
      { id: 24, title: "Guest Consultation for Barber", link: "https://docs.google.com/forms/d/1VgPjxmYUO6enJEIOnGM-BIFKmJl1iHWHRE0YpFeJ5BA/edit" },
      { id: 25, title: "Guest Consultation for Skin Care", link: "https://docs.google.com/forms/d/1Aas6uOQ4vyT1J6YP1xhAX9OKFEEcvyM9oET3MPiCBWA/edit" },
      { id: 26, title: "Guest Consultation for Massage", link: "https://docs.google.com/forms/d/1xkUvwNzPv-awz13aVlQqb-RBqu4SoITxrc2phpvw9mU/edit" },
      { id: 27, title: "Guest Consultation for Med Spa", link: "https://docs.google.com/forms/d/1QFR0aKiwP7mUsU546ill_TPg3ghvtwlSJa_NPP1aPP4/edit" },
      { id: 28, title: "Client Experience Survey", link: "https://docs.google.com/forms/d/1XJBMffV1g9seiW_R8G0qF74W5SCkLmGm48PSjDn7wz0/edit" },
      { id: 29, title: "Barber Release Form", link: "https://docs.google.com/forms/d/e/1FAIpQLScOhRUhK0tqgv6MR4Deu9_b9uET63i3iRgM9gMlLJT8yDmvRQ/viewform?usp=sharing" },
      { id: 30, title: "Barber Prescription RX", link: "https://docs.google.com/forms/d/e/1FAIpQLScOhRUhK0tqgv6MR4Deu9_b9uET63i3iRgM9gMlLJT8yDmvRQ/viewform?usp=sharing" },
      { id: 31, title: "Esthetician Professional Product Prescription RX", link: "https://docs.google.com/forms/d/1vgYdbHa8og41LhiqC9py1Z0ub5f3KlK2NFkQA8NJdM4/edit" },
      { id: 32, title: "Esthetician Release Form", link: "https://docs.google.com/forms/d/1H_w3rXmiTFTrf4BWP2WxYWCAZ6OvYta8_AQup0xgBPQ/edit" },
      { id: 33, title: "Eye Lash Extensions / Micro-blading Service Menu", link: "https://docs.google.com/forms/d/1HU0IbNPMSfkk9YomGGlWXFYIIxpltH1q24UfhZKPIu0/edit" },
      { id: 34, title: "Eyelash Extensions / Micro blading Professional Product Prescription RX", link: "https://docs.google.com/forms/d/1ggi8f9B09gcakSkbVxLz0qqNcqArkzdxSptLpD8HN74/edit" },
      { id: 35, title: "Hair Extensions Professional Product Prescription RX", link: "https://docs.google.com/forms/d/10NB7XYzLeKB6Pxx6v2_mKh1ZrI1QeYhW5KEc0xxZgj8/edit" },
      { id: 36, title: "Hair Extensions Technician Release Form", link: "https://docs.google.com/forms/d/1FepRm6AXxl7pamIivgpDPDB9gXn4x2ZJM9K77GXxcLo/edit" },
      { id: 37, title: "Hair Design Professional Product Prescription RX", link: "https://docs.google.com/forms/d/1zElyrOgF4c8XMfJPIe_fV4Gwdp49QJu7mG7Zv5Qx3bQ/edit" },
      { id: 38, title: "Hair Stylist Release Form", link: "https://docs.google.com/forms/d/1HIWAdJ2ojkMaTldrwHc4WQcspJ6zbmd-zSAFl6ytsMw/edit" },
      { id: 39, title: "Massage / Wellness Therapist Release Form", link: "https://docs.google.com/forms/d/1ozq8YwCbWSu6Cves8gmai8JWQMm83UD_pxwl403iJko/edit" },
      { id: 40, title: "Massage Wellness Professional Product Prescription RX", link: "https://docs.google.com/forms/d/19ZXM6NO-fZOYddjCSvL2j7YU40Xwa5a2v9YnzGgqZuI/edit" },
      { id: 41, title: "Med-SPA Technician  Release Form", link: "https://docs.google.com/forms/d/1XtLoWCUld2HOWEh0OZsOgeq56SV8eFGo8CDeFBmPelE/edit" },
      { id: 42, title: "Med-SPA Professional Product Prescription RX", link: "https://docs.google.com/forms/d/12k3c1UCKDWpWMZhau9T7WflHM8Btj9RyGalh1syed8M/edit" },
      { id: 43, title: "Nail Technician  Release Form", link: "https://docs.google.com/forms/d/14-k1jNQLpJBe5Iw7fe_xH_SSswrHgfHRyBruqzn9HUE/edit" },
      { id: 44, title: "Nail Professional Product Prescription RX", link: "https://docs.google.com/forms/d/1PHGDm4ENajZUpaf_V5C2bOeoopnhmHCaclD86fYc2-0/edit" },
    ]
  }
];

const docs = [
  // Add your docs here if needed
];

const Community = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const isPaid = location.state?.isPaid || false;

  const handleBack = () => {
    navigate('/user-profile');
  };

  const handlePayment = () => {
    window.location.href = 'https://book.stripe.com/bIY00Ybsk7Ew14Q6ox';
  };

  return (
    <Box maxWidth={isMobile ? "100%" : "800px"} margin="0 auto" p={isMobile ? 4 : 8}>
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        size="sm"
        mb={4}
        onClick={handleBack}
      >
        Return to Profile
      </Button>
      <Box textAlign="center" mb={8}>
        <Icon as={FaUserFriends} w={10} h={10} mb={4} color="blue.500" />
        <Heading as="h1" size={isMobile ? "lg" : "xl"} mb={4}>
          Business Tools and Resources
        </Heading>
        <Text fontSize={isMobile ? "md" : "lg"}>
          Access your treasure trove of tools from the community! Easily navigate your Docs and Forms that support your business. 🛠️🎉
        </Text>
      </Box>
      {/* Scheduling or payment section */}
      <Box mb={8}>
        <Heading as="h2" size={isMobile ? "md" : "lg"} mb={4} textAlign="center">
          Coaching Sessions
        </Heading>
        {isPaid ? (
          <ScheduleCoaching calendlyUrl="https://calendly.com/saloncoach100/live-business-coaching-session" />
        ) : (
          <Flex alignItems="center" justifyContent="center" height="200px">
            <Button colorScheme="blue" size={isMobile ? "md" : "lg"} onClick={handlePayment}>
              Schedule a Coaching Session
            </Button>
          </Flex>
        )}
      </Box>
      <VStack spacing={6} align="stretch">
        {docs && docs.length > 0 && (
          <>
            <Heading as="h2" size={isMobile ? "md" : "lg"} mb={4}>
              Most Helpful Docs
            </Heading>
            <List spacing={3}>
              {docs.map((doc) => (
                <ListItem key={doc.id}>
                  <Link href={`/forms/${doc.name}`} download fontSize={isMobile ? "sm" : "md"}>
                    {doc.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </>
        )}
        <Heading as="h2" size={isMobile ? "md" : "lg"} mb={4}>
          Most Helpful Forms
        </Heading>
        {forms.map((category) => (
          <Box key={category.category}>
            <Heading as="h3" size={isMobile ? "sm" : "md"} mb={2}>
              {category.category}
            </Heading>
            <List spacing={3}>
              {category.items.map((form) => (
                <ListItem key={form.id}>
                  <Link href={form.link} isExternal fontSize={isMobile ? "sm" : "md"}>
                    {form.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default Community;
