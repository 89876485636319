// src/Components/Performance/PerformanceGauges/index.js
// src/Components/Performance/PerformanceGauges/index.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  CircularProgress,
  CircularProgressLabel,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import {
  FiDollarSign,
  FiUsers,
  FiShoppingBag,
  FiRotateCw
} from 'react-icons/fi';

const PerformanceGauges = ({ performanceData = {} }) => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const handleSwitch = () => {
    setIsSpinning(true);
    setIsMonthly(!isMonthly);
    setTimeout(() => setIsSpinning(false), 600);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value || 0);
  };

  const currentPeriod = isMonthly ? 'monthly' : 'weekly';
  const currentData = performanceData[currentPeriod] || {};
  const previousData = performanceData[currentPeriod]?.previous || {};

  const targets = {
    weekly: {
      total_service_payments: 5000,
      total_item_payments: 2000,
      total_clients: 35
    },
    monthly: {
      total_service_payments: 20000,
      total_item_payments: 8000,
      total_clients: 120
    }
  };

  const metrics = [
    {
      icon: FiDollarSign,
      label: 'Services',
      current: currentData.total_service_payments || 0,
      previous: previousData.total_service_payments || 0,
      target: targets[currentPeriod].total_service_payments,
      format: formatCurrency,
      color: 'blue'
    },
    {
      icon: FiShoppingBag,
      label: 'Products',
      current: currentData.total_item_payments || 0,
      previous: previousData.total_item_payments || 0,
      target: targets[currentPeriod].total_item_payments,
      format: formatCurrency,
      color: 'green'
    },
    {
      icon: FiUsers,
      label: 'Clients',
      current: currentData.total_clients || 0,
      previous: previousData.total_clients || 0,
      target: targets[currentPeriod].total_clients,
      format: value => value.toString(),
      color: 'purple'
    }
  ];

  const calculatePercentage = (value, target) => {
    return Math.min((value / target) * 100, 100);
  };

  const bgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bgColor} borderRadius="lg" shadow="sm" p={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Text fontSize="sm" fontWeight="medium" color="gray.600">
          {isMonthly ? "Monthly" : "Weekly"} Performance
        </Text>
        <Button
          size="sm"
          variant="outline"
          onClick={handleSwitch}
          leftIcon={
            <Icon
              as={FiRotateCw}
              transform={isSpinning ? 'rotate(360deg)' : 'none'}
              transition="transform 0.6s"
            />
          }
        >
          View {isMonthly ? "Weekly" : "Monthly"}
        </Button>
      </Flex>

      <Flex justifyContent="space-around" wrap="wrap">
        {metrics.map((metric, index) => (
          <Box key={index} textAlign="center" px={4}>
            <CircularProgress
              value={calculatePercentage(metric.current, metric.target)}
              color={`${metric.color}.400`}
              size="120px"
              thickness="8px"
            >
              <CircularProgressLabel>
                <Icon as={metric.icon} color={`${metric.color}.500`} boxSize={6} mb={1} />
                <Text fontSize="lg" fontWeight="bold">
                  {metric.format(metric.current)}
                </Text>
                {metric.previous && (
                  <Text fontSize="xs" color={metric.current >= metric.previous ? "green.500" : "red.500"}>
                    {metric.current >= metric.previous ? '↑' : '↓'} 
                    {Math.abs(((metric.current - metric.previous) / metric.previous) * 100).toFixed(1)}%
                  </Text>
                )}
              </CircularProgressLabel>
            </CircularProgress>
            <Text mt={2} fontSize="sm" color="gray.600">
              {metric.label}
            </Text>
            <Text fontSize="xs" color="gray.500">
              Goal: {metric.format(metric.target)}
            </Text>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default PerformanceGauges;
