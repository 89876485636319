// src/Components/Footer.js
import React from 'react';
import { Box, Text, IconButton, Link, VStack, HStack, Container } from '@chakra-ui/react';
import { FaYoutube, FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <Box bg="gray.800" color="white" py={10}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="center">
          <VStack align="center" spacing={4}>
            <Text fontSize="lg" fontWeight="bold">Sole Business Solutions</Text>
            <Text>&copy; {new Date().getFullYear()} All rights reserved</Text>
          </VStack>
          <VStack align="center" spacing={4}>
            <Text fontSize="lg" fontWeight="bold">Follow Us</Text>
            <HStack spacing={4}>
              <Link href="https://www.youtube.com/@SuiteBusinessSolutions" isExternal>
                <IconButton
                  icon={<FaYoutube />}
                  isRound
                  size="lg"
                  bg="red.500"
                  _hover={{ bg: "red.600" }}
                  aria-label="YouTube"
                />
              </Link>
              <Link href="https://www.facebook.com/sbscoaches" isExternal>
                <IconButton
                  icon={<FaFacebook />}
                  isRound
                  size="lg"
                  bg="blue.500"
                  _hover={{ bg: "blue.600" }}
                  aria-label="Facebook"
                />
              </Link>
              <Link href="https://www.instagram.com/suitebusinesssolutions" isExternal>
                <IconButton
                  icon={<FaInstagram />}
                  isRound
                  size="lg"
                  bg="pink.500"
                  _hover={{ bg: "pink.600" }}
                  aria-label="Business Instagram"
                />
              </Link>
            </HStack>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};

export default Footer;
